.popover-container {
    position: relative;
    display: inline-block;
}

.popover-content {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 8px;
    z-index: 1000;
    max-width: 300px;
    border-radius: 4px;
    text-wrap: auto;
}

/* Placement styles */
.popover-bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;
}

.popover-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 8px;
}

.popover-left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 8px;
}

.popover-right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 8px;
}