.action-button {
    background-color: white;
    color: black;
    font-size: 14px;
    border-radius: 5px;
    height: 30px;
    padding: 4px;
    display: flex;
    align-items: center;
}

.action-button:hover {
    background-color: lightgray;
}

.action-button:disabled {
    opacity: 0.5;
}

.action-button svg {
    margin-right: 8px;
}

.border-none {
    border: none;
}

