.r-checkbox-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 5px;
    font-size: 18px;
}

.r-checkbox {
    width: 16px;
    height: 16px;
}

.r-checkbox-container label {
    margin-left: 8px;
}


.r-checkbox-container.md{
    font-size: 20px;
}

.r-checkbox-container.lg{
    font-size: 22px;
}

.r-checkbox {
    width: 16px;
    height: 16px;
}

.r-checkbox.md{
    width: 20px;
    height: 20px;
}

.r-checkbox.lg{
    width: 24px;
    height: 24px;
}




