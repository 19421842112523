.basic-table-container {
    padding: 15px;
}

.basic-table-container td {
    border-style: none !important;
    border-bottom: 1px solid #ddd !important;
}

.basic-table-actions-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

/* search filter */
.basic-table-actions-right {
    position: relative;
    float: right;
}

.basic-table-actions-right .search-filter {
    width: 250px;
    height: 30px;
    font-size: 14px;
    border-radius: 5px;
}

.basic-table-actions-right .clear-button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
}

.basic-table-container .basic-table-header-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.basic-table-container .basic-table-container-overflow{
    overflow: auto;
}

.basic-table-container .basic-table-container-vertical-scroll{
    max-height: 700px; 
    overflow-y: auto;
}

.basic-table-container .basic-table-content{
    min-width: 100%; 
    border-collapse: collapse; 
    border-width: 1px; 
    border-color: rgb(209,213,219);
}

.basic-table-container .basic-table-header{
    background-color: rgb(78, 201, 242);;
}

.basic-table-container .basic-table-header th{
    cursor: pointer; 
    border-width: 1px;
    padding: 0.5rem;
}

.basic-table-container .basic-table-body tr:hover{
    background-color: rgb(249,250,251);
}

.basic-table-container .basic-table-body td{
    border-width: 1px; 
    padding: 0.5rem;
}
