.server-table-container {
    padding: 15px;
}

.server-table-container td {
  border-style: none !important;
  border-bottom: 1px solid #ddd !important;
}

.server-table-actions-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.server-table-actions-right {
  position: relative;
  float: right;
}

.server-table-actions-right .search-filter {
  width: 250px;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
}

.server-table-actions-right .clear-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.server-table-container-overflow {
  overflow: auto;
}

.server-table-container .server-table-content {
  min-width: 100%;
  border-collapse: collapse;
  border-width: 1px; 
  border-color: rgb(209,213,219);
}

.server-table-header {
  background-color: rgb(78, 201, 242);
}

.server-table-header th {
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid #ddd;
}

/* Row hovering */
.server-table-body tr:hover {
  background-color: rgb(249, 250, 251);
}

.server-table-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.server-table-pagination button {
  margin-right: 5px;
}

.server-table-pagination input[type="number"] {
  width: 60px;
  padding: 5px;
  margin: 0 5px;
}

.server-table-pagination select {
  margin-left: 5px;
  padding: 5px;
}