body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #17203f;
  color: whitesmoke; */
}
body::-webkit-scrollbar-track {
  background: black; /* color of the tracking area */
}
body::-webkit-scrollbar {
  width: 14px;
  display: none; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: gray; /* color of the scroll thumb */ /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates padding around scroll thumb */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  /* background-color: #ADD8E6; */
  background-color: rgb(78, 201, 242);
  /* border-style: solid ; */
  border-width: 1px;
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: larger;
  padding: 0px 36px;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 21;
}

.header .header-left{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 24px;
  gap: 15px;
  color: black;
}

.header img {
  margin: 5px 0px;
  height: 50px;
  width: 100px;
}

.header img:first-child {
  width: 120px;
  cursor: pointer;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #d3d3d3;
  flex-direction: row;
  gap: 30px;
}

.select,
.alldata,
.compdata {
  margin: 5px;
  background-color: #17203f;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.select div:first-child {
  border-bottom: 1px solid rgb(125, 211, 252);
  font-weight: bolder;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: rgb(125, 211, 252);
}

.select div:last-child {
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.alldata {
  padding: 0px 16px 20px 16px;
}
.finance {
  margin-top: 16px;
  padding-right: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.finance div {
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .finance {
    /* width: 100%; */
    justify-content: flex-start;
  }
  .finance div {
    margin-right: 10px;
  }
}

.option p:first-child {
  font-weight: bolder;
  color: rgb(125, 211, 252);
}

.option hr {
  background-color: rgb(125, 211, 252);
}

.card {
  margin: auto;
  margin-top: 40px;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 10px 28px;
  transition: transform 0.2s;
  width: 20%;
  text-align: center;
  /* background-color: #0c1427; */
  /* color: whitesmoke; */
}

.tile-container {
  display: flex;
  width: 75%;
  margin: 40px auto;
  flex-wrap: wrap;
}

.tile-container .card {
  width: 24%;
}

.ml-20 {
  margin-left: 20px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__view-calendar-icon input {
  padding: 10px 10px 10px 30px !important;
}

input:disabled {
  background-color: aliceblue;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 120px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 55px;
  text-align: center;
  background-color: #eaecee;
  color: #475e75;
}


.table-container {
  max-height: 600px; /* Limits height to show vertical scroll */
  overflow: auto;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 15px;
  border-radius: 5px;

}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  position: sticky;
  top: 0px;
  background-color: rgb(78, 201, 242);
  color: black;
  z-index: 2;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.search-form {
  width: 350px;
}

.secondary-button {
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #6c757d; /* Bootstrap's secondary border color */
  cursor: pointer;
  background-color: #6c757d; /* Bootstrap's secondary background color */
  width: 100%;
  font-size: 16px;
  color: white; /* White text for contrast */
  transition: all 0.2s ease-in-out;
}

.secondary-button:hover {
  background-color: #5a6268; /* Darker shade on hover */
  border-color: #545b62;
}

.secondary-button:enabled:active {
  transform: scale(0.96);
  -webkit-transform: scale(0.96);
  background-color: #545b62; /* Slightly darker on click */
  border-color: #4e555b;
}

.secondary-button:disabled {
  background-color: #adb5bd;
  border-color: #adb5bd;
  color: white;
  cursor: not-allowed;
}


/* Base styles for all outline buttons */
.outline-button {
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 2px solid;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
}

/* Primary */
.outline-button-primary {
  border-color: rgb(78, 201, 242);
  color: rgb(78, 201, 242);
}

.outline-button-primary:hover {
  background-color: rgb(78, 201, 242);
  color: white;
}

/* Secondary */
.outline-button-secondary {
  border-color: #6c757d;
  color: #6c757d;
}

.outline-button-secondary:hover {
  background-color: #6c757d;
  color: white;
}

/* Success */
.outline-button-success {
  border-color: #28a745;
  color: #28a745;
}

.outline-button-success:hover {
  background-color: #28a745;
  color: white;
}

/* Danger */
.outline-button-danger {
  border-color: #dc3545;
  color: #dc3545;
}

.outline-button-danger:hover {
  background-color: #dc3545;
  color: white;
}

/* Warning */
.outline-button-warning {
  border-color: #ffc107;
  color: #ffc107;
}

.outline-button-warning:hover {
  background-color: #ffc107;
  color: black;
}

/* Info */
.outline-button-info {
  border-color: #17a2b8;
  color: #17a2b8;
}

.outline-button-info:hover {
  background-color: #17a2b8;
  color: white;
}

/* Light */
.outline-button-light {
  border-color: #f8f9fa;
  color: #f8f9fa;
}

.outline-button-light:hover {
  background-color: #f8f9fa;
  color: black;
}

/* Dark */
.outline-button-dark {
  border-color: #343a40;
  color: #343a40;
}

.outline-button-dark:hover {
  background-color: #343a40;
  color: white;
}

/* Disabled state */
.outline-button:disabled {
  border-color: #adb5bd;
  color: #adb5bd;
  cursor: not-allowed;
  background-color: transparent;
}

.error-status {
  color: #d32f2f; /* Red for error */
  font-size: 16px;
  font-weight: bold;
}

.loading-status {
  color: #1976d2; /* Blue for loading */
  font-size: 16px;
  font-weight: bold;
}

.upload-button {
  margin-bottom: -45px;
  margin-left: 15px;
}