.screenContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    margin-top: 40px;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin: 10px;
}

.tableWrapper{
    width: 500px;
}

.statusButton {
    background: transparent;
    border: none;
    text-decoration: underline;
    font-weight: bold;
    color: lightgray;
}

.statusButtonActive {
    color: lightgreen;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.createdContainer {
    border: 1px solid black;
    padding: 20px;
    border-radius: 8px;
}