.main {
  margin: auto;
  margin-top: 40px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 10px 28px;
  transition: transform 0.2s;
  width: 500px;
}
@media only screen and (max-width: 768px) {
  .main {
    margin: 40px 0px 0px 0px;
    width: auto;
  }
}
h3 {
  text-align: center;
}
form label {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
}

form input {
  display: block;
  width: 100%;
  outline: none;
  margin-bottom: 15px;
  font-size: medium;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
}
form select {
  display: block;
  width: 100%;
  outline: none;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: medium;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.pwdToggle {
  display: inline;
  width: auto;
  margin-right: 10px;
  text-align: justify;
}

.primary-button {
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: none;
  cursor: pointer;
  background-color: rgb(78, 201, 242);
  width: 100%;
  font-size: 16px;
}

.primary-button:enabled:active {
  transform: scale(0.96);
  -webkit-transform: scale(0.96);
}

.primary-button:disabled {
  color: black;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  color: rgb(202, 28, 28);
  font-weight: bold;
}

.main .dec {
  text-decoration: underline;
  cursor: pointer;
}
