.profile {
  position: relative;
  font-size: 40px;
  /* height: 45px; */
  /* width: 148px; */
  text-align: center;
  cursor: pointer;
  color: black;
}

.profile-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 5px;
  overflow: auto;
  outline: none;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 14px;
  background-color: white;
  color: #096177;
  box-sizing: border-box;
  letter-spacing: 0.8px;
  height: 0px;
  transition: 350ms linear;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Added box-shadow */

}

.profile:hover .profile-options {
  display: block;
  width: 160px;
  height: fit-content;
  padding: 6px 6px;
}

.profile-options div {
  padding: 2px 2px;
}

.profile-options div:hover {
  background-color: rgb(208, 205, 205);
}
