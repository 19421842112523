/* Table container */
.common-table-container {
    margin: 15px;
}

.common-table-actions-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.common-table-actions-left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: left;
    align-items: center;
}   


/* search filter */
.common-table-actions-right{
    position: relative;
    float: right;
}

.common-table-actions-right .search-filter {
    width: 250px;
    height: 30px;
    font-size: 14px;
    border-radius: 5px;

}

.common-table-actions-right .clear-button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
}

/* Table */
.common-table {
    width: 100%;
    border-collapse: collapse;
}

/* Table Header */
.common-table thead th{
    background-color: rgb(78, 201, 242);
}

.common-table th {
    padding: 10px;
}

.common-table-header-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* styles overriding bootstrap classes */
.common-table td.p-2{
    padding: 0px !important;
    padding: 8px !important;
}

.common-table td.border{
    border: none !important;
    border-bottom: 1px solid #ddd !important;
}

/* Table Body */
/* .common-table td {
    padding: 8px !important;
    border-bottom: 1px solid #ddd !important;
} */

/* Hover effect on rows */
.common-table tbody tr:hover {
    background-color: #f5f5f5;
}

/* Row selection */
.common-table tbody tr.selected {
    background-color: #e3f2fd;
}

/* Editable row styling */
.common-table tbody tr.editable td {
    background-color: #fff3e0;
}

/* Pagination Controls */
.pagination-controls {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    align-items: center;
    font-size: 14px;
}

.pagination-container{
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
}

.pagination-controls button {
    padding: 6px;
    margin: 0 5px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    background-color: white;
    color: black;
    text-decoration: underline;
}

.pagination-controls button:disabled {
    cursor: not-allowed;
    color: lightgray;
}

/* Column visibility toggle buttons */
.common-table-container .column-toggle-container {
    margin-bottom: 10px;
}

.common-table-container .column-toggle-container button {
    margin-right: 5px;
    padding: 5px 10px;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.common-table-container .column-toggle-container button.active {
    background-color: #3949ab;
}

.common-table-container .column-toggle-container button:hover {
    opacity: 0.8;
}
/* Table Row */
.table-row {
    position: relative;
    transition: background-color 0.2s ease-in-out;
  }
  
  /* Show row hover effect */
  .table-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

/* Editable Input */
.editable-input {
    width: 100%;
    padding: 4px;
    border: 1px solid black;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Action Buttons Container */
  .row-hover-actions {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    gap: 8px;
    background: rgba(255, 255, 255, 0.9);
    padding: 5px 8px;
    border-radius: 4px;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Hide Actions by Default */
  .row-hover-actions {
    opacity: 0;
    pointer-events: none;
  }
  
  /* Show Actions on Row Hover */
  .table-row:hover .row-hover-actions {
    opacity: 1;
    pointer-events: auto;
  }
  
  /* Action Icons */
  .action-icon {
    cursor: pointer;
    font-size: 18px;
    transition: color 0.2s;
  }


  .common-table-container table td:first-child {
    width: 30px;
  }

  .common-table-container table td:nth-child(2) {
    width: 50px;
  }

  .common-table-container .edit-cell-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .common-table-container .edit-cell-container button{
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
  }

  .common-table-container td {

  }